import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import LogosStrip from '@solid-ui-components/LogosStrip'

import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalPortfolio from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block02'
import Team from '@solid-ui-blocks/Teams/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import Programs from '@solid-ui-blocks/Features/Block01'
import ContentMap from '@solid-ui-components/ContentMap'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'

const PainManagement = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const heroData = props.data.allBlockContent.nodes.find(
    element => element.identifier === 'hero'
  )
  let thumbnail = heroData.images[0].src.childImageSharp.gatsbyImageData

  return (
    <Layout {...props}>
      <Seo
        title='Pain Management'
        indexed={true}
        thumbnail={thumbnail}
        keywords={[]}
      />
      {/* Modals */}
      {/* <ModalWithTabs content={content['authentication']} reverse /> */}
      <ModalWithTabs content={content['contact']} />
      <ModalPortfolio content={content['dr-krishna-c-chunduri']} />
      <ModalPortfolio content={content['dr-darrel-saldanha']} />

      {/* <ModalSimple content={content['advertisement']} /> */}
      {/* Blocks */}
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Container variant='full' sx={styles.insuranceContainer}>
        <LogosStrip content={content['insurance']} />
      </Container>
      <Divider space='4' />
      <Content content={content['content-four']} />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-one']} reverse />
      </Container>
      <Divider space='4' />
      <Content content={content['content-one']} />
      <Divider space='4' />
      <Content content={content['content-two']} />
      <Divider space='4' />
      <Content content={content['content-three']} />
      <Divider space='4' />
      <Container variant='full'>
        <Contact content={content['pricing-and-insurance']} />
      </Container>
      <Divider space='4' />
      <Container variant='full' sx={styles.programs}>
        <Programs
          content={content['neighborhood']}
          wrapperStyles={styles.programsWrapper}
        />
      </Container>
      <Divider space='4' />
      <Team content={content['physicians']} />
      <Divider space='4' />
      <Divider space='4' />
      <div style={{ width: `100%`, height: `600px` }}>
        <ContentMap
          content={{
            lat: 42.0985394,
            lng: -87.9600665,
            zoom: 14
          }}
        />
      </div>
      {/* <Divider space='4' />
      <Container variant='wide' sx={styles.contentTwoContainer}>
        <Content content={content['content-two']} />
      </Container> */}
      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query servicesRandPainManagementBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/services/pain-management", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PainManagement
